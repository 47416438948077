import React from 'react';
import logo from './logo.svg';
import './App.css';
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { PutCommand, DynamoDBDocumentClient } from "@aws-sdk/lib-dynamodb";

function App() {
  const client = new DynamoDBClient({});
  const docClient = DynamoDBDocumentClient.from(client);

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Get the input values from the form
    const name = (document.getElementById('name') as HTMLInputElement).value;
    const email = (document.getElementById('email') as HTMLInputElement).value;

    // Display the form data in the output element
    const output = document.getElementById('output');
    if (output) {
        // output.innerHTML = `<p>Name: ${name}</p><p>Email: ${email}</p>`;
        output.innerHTML = `<p style='margin-top: 50px'>Thanks ${name}!</p>`;
    }

    const command = new PutCommand({
      TableName: "email-addresses",
      Item: {
        email: email,
        verified_status: '1',
      },
    });
};
  return (
    <div className="App">
      <header className="App-header">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"/>
      <div className='stacked-div'>
        <div className="emoji-container">
          <i className="emoji far fa-sad-tear" data-rank="1"></i>
          <i className="emoji far fa-frown" data-rank="2"></i>
          <i className="emoji far fa-meh-blank" data-rank="3"></i>
          <i className="emoji far fa-smile" data-rank="4"></i>
          <i className="emoji far fa-grin-stars" data-rank="10"></i>
          {/* <i className="emoji far fa-meh" data-rank="5"></i>
          <i className="emoji far fa-laugh-beam" data-rank="6"></i>
          <i className="emoji far fa-smile-beam" data-rank="7"></i>
          <i className="emoji far fa-laugh-squint" data-rank="8"></i>
          <i className="emoji far fa-laugh-wink" data-rank="9"></i> */}
        </div>
        {/* <div className='stacked-div'>
          <p>You feel like: <span id="feelingRating">X</span></p>
        </div> */}
        <div>
          <div>
            <h1>An email a day to track your wellness</h1>
          </div>
          <div className='app-description'>
            <div className='description-child'>
              <h6>Reply like it's another email, except this one is good for you. Jot your thoughts and choose a rating. Receive a trend report per week and month. Cancel anytime</h6>
            </div>
          </div>  
        </div>  
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSf_tQI260tvOT2p-Fnym_DTFM9ugBKsU8Wfy3IfG8_h-Eu-jA/viewform?embedded=true"
          height="824"
          frameBorder="0"
          width="640"
        >
          Loading…
        </iframe>
        {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf_tQI260tvOT2p-Fnym_DTFM9ugBKsU8Wfy3IfG8_h-Eu-jA/viewform?embedded=true" width="640" height="824" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
        <div>
            {/* <h6>For you.</h6> */}
            
            {/* <div className="horizontal-line">
              <input type="text" placeholder="Enter text"/>
            </div> */}
        </div>
        </div>
        {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
    </div>
  );
}

export default App;
